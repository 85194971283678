import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
// import { PasswordResetComponent } from './password-reset/password-reset.component';
import { AuthGuard } from './auth.guard';
// import { SendPasswordResetEmailComponent } from './send-password-reset-email/send-password-reset-email.component';
// import { PasswordResetUuidComponent } from './password-reset-uuid/password-reset-uuid.component';
import { DialogGuard } from './dialog.guard';

const routes: Routes = [
	{
		path: 'login',
		canDeactivate: [DialogGuard],
		component: LoginComponent,
		pathMatch: 'full'
	},
	// {
	// 	path: 'password-reset-uuid',
	// 	component: PasswordResetUuidComponent,
	// },
	// {
	// 	path: 'send-password-reset-email',
	// 	component: SendPasswordResetEmailComponent,
	// 	pathMatch: 'full'
	// },
	// {
	// 	path: 'password-reset',
	// 	canDeactivate: [DialogGuard],
	// 	component: PasswordResetComponent,
	// 	pathMatch: 'full'
	// }
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [
		RouterModule
	]
})
export class AuthRoutingModule { }
