export const environment = {
	name: "dev",
	production: false,
	// devUser: "",
	// devPass: "",
	homeUrl: "administracion/dia-no-laborable-list",
	devUser: "admin",
	devPass: "1234qwer",
	// homeUrl: "admin/user-list",
	// homeUrl: "campana-fecha-cierre-list",
	// now: "2022-03-20T00:00:01",
	now: "",

	baseUrl: "https://portal-service-dmg.ebinaria.com.ar/dmg-services/resources",
	productImageBaseUrl: "http://192.168.0.44:8082/dmg-services/resources"

	// baseUrl: "http://192.168.0.45:8082/dmg-services/resources",
	// productImageBaseUrl: "http://192.168.0.45:8082/dmg-services/resources"
	
};
