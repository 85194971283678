import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './login/login.component';
// import { PasswordResetComponent } from './password-reset/password-reset.component';
// import { SendPasswordResetEmailComponent } from './send-password-reset-email/send-password-reset-email.component';
// import { PasswordResetUuidComponent } from './password-reset-uuid/password-reset-uuid.component';
import { NgBaseAngularMaterialModule } from '../ng-base-angular-material.module';
import { AuthRoutingModule } from './auth-routing.module';



@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		// FlexLayoutModule,
		NgBaseAngularMaterialModule,
		AuthRoutingModule,
		// CommonComponentModule
	],
	declarations: [
		LoginComponent
		// PasswordResetComponent,
		// PasswordResetUuidComponent,
		// SendPasswordResetEmailComponent
	]
})
export class AuthModule { }
