<div class="container">
	<mat-card class="accent-lighter">
		<mat-card-title><img src="assets/images/logo.png" width="80%" class="padding-top padding-dbl-bottom"></mat-card-title>
		<mat-card-content class="padding-top">
			<form [formGroup]="form" (ngSubmit)="submit()" autocomplete="off" spellcheck="false" id="form">

				<mat-form-field>
					<mat-label>Usuario</mat-label>
					<input type="text" matInput formControlName="username">
				</mat-form-field>

				<mat-form-field>
					<mat-label>Contraseña</mat-label>
					<input  [type]="hidePass ? 'password' : 'text'" matInput formControlName="password">
					<button mat-icon-button matSuffix (click)="hidePass = !hidePass" type="button">
						<mat-icon>{{hidePass ? 'visibility_off' : 'visibility'}}</mat-icon>
					</button>
				</mat-form-field>
				
				<button type="submit" class="width-100" mat-raised-button color="accent">Ingresar</button>
				
				<p *ngIf="error" class="error">
					{{ error }}
				</p>
				<div (click)="forgotPass()" class="forgot-pass">Recuperar mi contraseña</div>
			</form>
		</mat-card-content>
	</mat-card>	

</div>
