import { Injectable, EventEmitter } from '@angular/core';
import { LoggerService } from '../../service/logger/logger.service';


@Injectable({
	providedIn: 'root'
})
export class ErrorDialogService {

	errorEmitter: EventEmitter<any>;

	constructor(private logger: LoggerService) {
		this.logger.i("error-dialog.service: constructed");
		this.errorEmitter = new EventEmitter();
	}

	open(message?: string, title?: string, fatal?: boolean){
		this.logger.i("error-dialog.service: " + "open");
		let obj: any = {};
		if(title){
			obj["title"] = title;
		}else{
			obj["title"] = "Error";
		}
		if(message){
			obj["message"] = message;
		}else{
			obj["message"] = "Ha ocurrido un error";
		}
		this.errorEmitter.emit(obj);
	}
}
