import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { AuthService } from '../auth.service';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { SnackBarService } from '../../component/snack-bar/snack-bar.service';
import { LoggerService } from '../../service/logger/logger.service';
import { LoadingIndicatorService } from '../../component/loading-indicator/loading-indicator.service';
import { ErrorDialogService } from '../../component/error-dialog/error-dialog.service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
	hidePass = true;
	constructor(private authService: AuthService,
		private router: Router,
		private route: ActivatedRoute,
		private logger: LoggerService,
		private snackBar: SnackBarService,
		private formBuilder: FormBuilder,
		private loading: LoadingIndicatorService,
		private errorDialog: ErrorDialogService) {
		this.logger.i("login.component: constructed");

	}

	private authSubs: Subscription;

	ngOnInit() {
		this.logger.i("login.component: " + "onInit");

		this.initForm();

		this.authSubs = this.route.queryParams
			.subscribe((params: any) => {
				this.logger.i("login.component: " + JSON.stringify(params, null, 2));
				if (params.status) {
					this.error = params.status;
				}
			});


		if (!environment.production && environment.devUser != "") {
			this.form.controls.username.setValue(environment.devUser);
			this.form.controls.password.setValue(environment.devPass);
			// this.submit();
		}

	}

	ngOnDestroy() {
		this.authSubs.unsubscribe();
	}

	form: FormGroup;
	initForm() {
		this.form = this.formBuilder.group({
			username: new FormControl(''),
			password: new FormControl(''),
		});
	}

	submit() {
		if (this.form.valid) {
			// this.loading.show();

			const username = this.form.controls.username.value.trim();
			const password = this.form.controls.password.value.trim();

			this.authService.login(username, password)
				.then((redirectUrl) => {
					this.logger.i("login.component: " + JSON.stringify(this.authService.user, null, 2));
					this.logger.i("login.component: " + "redirect url " + redirectUrl);
					if (this.authService.isLoggedIn()) {
						if (!this.authService.user.expired) {
							// Get the redirect URL from our auth service
							// If no redirect has been set, use the default						
							this.logger.i("login.component: " + redirectUrl);

							// Set our navigation extras object
							// that passes on our global query params and fragment
							let navigationExtras: NavigationExtras = {
								queryParamsHandling: 'preserve',
								preserveFragment: true
							};
							// Redirect the user
							this.logger.i("login.component: " + redirectUrl);
							this.router.navigateByUrl(redirectUrl, navigationExtras);

						} else {
							this.logger.i("login.component: " + "3333333333333");
							this.router.navigate(["password-reset"]);
							this.loading.hide();
						}

					}
				})
				.catch((error) => {
					this.logger.e("login.component: " + JSON.stringify(error, null, 2));
					if (error.fatal) {
						this.error = "";
						this.errorDialog.open(error.message);
					} else {
						this.snackBar.showError(error.message);
					}
					this.loading.hide();
				});
		}
	}

	@Input() error: string | null;

	forgotPass() {
		this.router.navigate(["send-password-reset-email"]);
	}

}



